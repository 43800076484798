import { atom } from "jotai";

export type AudioAtom = {
    element: HTMLAudioElement | null,
    audioSrc: string | undefined,
    key: string | undefined,
    title: string,
    subtitle: string,
    isPlaying: boolean,
    isClosed: boolean,
}

const initial: AudioAtom = {
    element: null,
    audioSrc: undefined,
    key: undefined,
    title: "",
    subtitle: "",
    isPlaying: false,
    isClosed: true,
};

export const globalAudioAtom = atom(initial);

import React from 'react';
import styles from "./Page2.module.scss";
import { AudioFooter } from '../components/AudioFooter';
import { Header } from '../components/Header';
import { Line } from '../components/Line';
import { SocialFooter } from '../components/SocialFooter';
import { useStrings } from '../localization/hooks/useStrings';
import CoverSrc from "../assets/images/layout/pagani_storia.png";
import { page2Entries } from '../models/AudioModel';
import { AudioItem } from '../components/AudioItem';
import { page3Entries } from '../models/TextModel';
import { TextItem } from '../components/TextItem ';
import { AudioTextItem } from '../components/AudioTextItem';


type Props = {
    className?: string;
};


export default function Page2(props: Props) {
    const strings = useStrings();
    console.log('page2Entries', page2Entries);

    return (
        <>
            <Header hasMenuButton={true} />
            <div className={`page ${styles.page} ${props.className ?? ""}`}>
                <h2 className="title">
                    {strings.page2$title}
                </h2>
                {/*  <p className="subtitle page-subtitle">
                    {strings.page2$subtitle}
                </p> */}
                <img
                    className={styles.image}
                    src={CoverSrc}
                />

                {/*  <div className={`text ${styles.text}`}>
                        {strings.page2$text}
                    </div> */}

                {page2Entries.map(model => (
                    <div>
{/*                     <AudioItem
                        key={model.title}
                        model={model}
                        audioSrc={'audio/history/'}
                    /> */}
                    <AudioTextItem
                            key={model.title}
                            model={model}
                            audioSrc={'audio/history/'}
                    />
                    </div>
                ))}

{/*                 <h2> VETTURE </h2>

                {page3Entries.map(model => (
                    <TextItem
                        key={model.title}
                        model={model}
                    />
                ))} */}
                <SocialFooter className={styles.social} />
            </div>
            <AudioFooter />
        </>
    );
}

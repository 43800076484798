import React, { useState } from "react";
import { useStrings } from "../localization/hooks/useStrings";
import styles from "./AudioItem.module.scss";
import IconSrc from "../assets/images/icons/icon-audio.svg";
import PauseSrc from "../assets/images/icons/icon-pause.svg";
import PlaySrc from "../assets/images/icons/icon-play-alt.svg";
import { Line } from "./Line";
import { formatSecondsToMmSs } from "../mz/timeUtils";
import { useAudioDurationSeconds } from "../mz/hooks/useAudioDurationSeconds";
import { useAtom } from "jotai";
import { globalAudioAtom } from "../atoms/audioAtom";
import { AudioModel } from "../models/AudioModel";
import NameSquare from "./NameSquare";

type Props = {
    model: AudioModel;
    className?: string;
    audioSrc: string;
}

export function AudioItem(props: Props) {
    const strings = useStrings();
    const model = props.model;

    const [audio, setAudio] = useState<HTMLAudioElement | null>();
    const audioSeconds = useAudioDurationSeconds(audio);

    const [globalAudio, setGlobalAudio] = useAtom(globalAudioAtom);

    const playing = globalAudio.key === model.audio && globalAudio.isPlaying;


    return (
        <div className={`${styles.wrapper} ${props.className ?? ""}`}>
            <img
                className={styles.icon}
                src={IconSrc}
            />
            <div className={`subtitle ${styles.title}`}>
                {strings[model.title]}
            </div>
            <div className={styles.time}>
                {formatSecondsToMmSs(audioSeconds)}
            </div>
            <NameSquare name={strings[model.name]} />
            <button
                className={`${styles.button} ${!playing ? "alt-all" : ""}`}
                onClick={() => {
                    if (!audio)
                        return;

                    setGlobalAudio(current => ({
                        ...current,
                        audioSrc: audio.src,
                        key: model.audio,
                        title: strings[model.title],
                        subtitle: "Panel I",
                        isPlaying: current.key !== model.audio || !current.isPlaying,
                        isClosed: false,
                    }));
                }}
            >
                <img src={playing ? PauseSrc : PlaySrc}/>
                <span className={`${styles.state} ${playing ? styles.playing : styles.notPlaying}`}>
                    {playing ? strings.common$playing : strings.common$play}
                </span>
            </button>
            <Line className={styles.line} />
            <audio
                ref={el => setAudio(el)}
                src={props.audioSrc + strings[model.name]?.toLowerCase() + strings[model.audio]}
                // src={dbgAudioSrc}
                preload="metadata"
            />
        </div>
    );
}

import React from "react";
import ReactSlider from "react-slider";

import styles from "./CustomSlider.module.scss";

type Props = {
    min?: number;
    max?: number;
    value?: number;
    onBeforeChange?: ((value: number, index: number) => void) | undefined;
    onAfterChange?: ((value: number, index: number) => void) | undefined;
    className?: string;
}

export function CustomSlider(props: Props) {
    return (
        <ReactSlider
            className={`${styles.slider} ${props.className}`}
            trackClassName="slider-track"
            thumbClassName={styles.thumb}
            min={props.min}
            max={props.max}
            value={props.value}
            onBeforeChange={props.onBeforeChange}
            onAfterChange={props.onAfterChange}
        />
    );
}

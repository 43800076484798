import { DependencyList, useEffect, useState } from "react";

import { noDeps } from "../noDeps";

/**
 * This is needed because in react you can't use audio.duration, because usually it's not ready yet
 * @param audioElement
 * @param deps additional dependencies
 * @returns
 */
export function useAudioDurationSeconds(audioElement: HTMLAudioElement | undefined | null, deps?: DependencyList) {
    const [audioSeconds, setAudioSeconds] = useState<number>();

    useEffect(
        () => {
            setAudioSeconds(audioElement?.duration);

            if (audioElement)
                audioElement.ondurationchange = () => setAudioSeconds(audioElement!.duration);
        },
        [audioElement, audioElement?.duration, ...(deps ?? noDeps)],
    );

    return audioSeconds;
}

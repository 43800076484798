import React from "react";

import styles from "./Line.module.scss";

type Props = {
    className?: string;
}

export function Line(props: Props) {
    return (
        <div className={`${styles.line} ${props.className ?? ""}`} />
    );
}

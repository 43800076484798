import React, { useEffect } from "react";
import styles from "./ArticlePage.module.scss";
import { Header } from "../components/Header";
import { SocialFooter } from "../components/SocialFooter";
import CoverSrc from "../assets/images/layout/pagani_vetture.png";
import { useAtomValue } from "jotai";
import { globalArticleAtom } from "../atoms/articleAtom";
import { useLocation } from "react-router-dom";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { Interweave } from "interweave";
import { page2DetailRoute, page2Route } from "./RoutePath";

type Props = {
  className?: string;
};

export default function ArticlePage(props: Props) {
  const articleDetails = useAtomValue(globalArticleAtom);
  const navigate = useAppNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname === page2DetailRoute && articleDetails.text === "") {
      navigate(page2Route);
    }
  }, [pathname, articleDetails]);

  return (
    <>
      <Header hasMenuButton={true} />
      <div className={`page ${styles.page} ${props.className ?? ""}`}>
        <h2 className={`title ${styles.articleTitle}`}>
          {articleDetails.title}
        </h2>
        <div className={`text ${styles.text}`}>
          <Interweave
            content={articleDetails.text.replace("\n", "<br>")}
          />
        </div>
        <SocialFooter className={styles.social} />
      </div>
    </>
  );
}

import { useState } from "react";
import { useNullableCallback } from "./useNullableCallback";
import { useRequestAnimationFrame } from "./useRequestAnimationFrame";


export function useAudioCurrentSeconds(audio: HTMLAudioElement | null) {
    const [currentSeconds, setCurrentSeconds] = useState<number>();

    useRequestAnimationFrame(useNullableCallback(
        audio === null
            ? null
            : () => setCurrentSeconds(audio.currentTime),
        [audio],
    ));

    return currentSeconds;
}

import { type } from 'os';
import React from 'react'
import styles from "./NameSquare.module.scss";

type Props = {
    name: string
}

export default function NameSquare(props: Props) {
  return (
    <div className={styles.wrapper}>
        {props.name}
    </div>
  )
}

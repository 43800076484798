import { useEffect } from "react";

/**
 * You might want to pass a useCallback(() => ...) argument, otherwise the callback gets replaced every render.
 * Note that if @param callback is null, requestAnimationFrame is not called at all.
 * @param callback
 */
export function useRequestAnimationFrame(callback: (() => void) | null) {
    useEffect(
        () => {
            if (callback === null)
                return;

            let frameId: number;
            let isCanceled = false;

            function requestRepeatingAnimationFrames() {
                frameId = requestAnimationFrame(() => {
                    callback!();

                    if (!isCanceled)
                        requestRepeatingAnimationFrames();
                });
            }

            requestRepeatingAnimationFrames();

            return () => {
                isCanceled = true;
                cancelAnimationFrame(frameId);
            };
        },
        [callback],
    );
}

import React from "react";
import { coalesceNaN } from "../mz/coalesceNaN";
import { useAudioCurrentSeconds } from "../mz/hooks/useAudioCurrentSeconds";
import { useAudioDurationSeconds } from "../mz/hooks/useAudioDurationSeconds";

import { CustomSlider } from "./CustomSlider";

type Props = {
    audio: HTMLAudioElement | null;
    className?: string;
}

export function AudioSlider(props: Props) {
    const audio = props.audio;

    const durationSeconds = useAudioDurationSeconds(audio);
    const currentSeconds = useAudioCurrentSeconds(audio);

    return (
        <CustomSlider
            min={0}
            max={coalesceNaN(durationSeconds ?? 0, 0)}
            value={currentSeconds ?? 0}
            onAfterChange={value => {
                if (audio)
                    audio.currentTime = value;
            }}
            className={props.className}
        />
    );
}

import StringsJson from "./LocalizedStrings";

export { StringsJson };

export declare type Locale = keyof typeof StringsJson;

export const defaultLocale: Locale = "it";

export type LocalizationKey = keyof typeof StringsJson["it"];

const browserLanguage = navigator.language || ((navigator as any).userLanguage as string);

export const interfaceLocale = browserLanguage?.substring(0, 2) as Locale;

export declare type LocalizedValue<T> = Record<Locale, T>;

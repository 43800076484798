import React from "react";

import styles from "./PanelItem.module.scss";
import imageBack from "../assets/images/icons/icon-back-white.svg";

type Props = {
    title: string;
    subtitle: string;
    bottom: string;
    onClick?: () => void;
    className?: string;
}

export function PanelItem(props: Props) {
    return (
        <div className={`${styles.wrapper} ${props.className ?? ""}`}>
            <button
                className={styles.box}
                onClick={props.onClick}
            >
                <div className={styles.title}>
                    {props.title}
                </div>
                <div className={styles.subtitle}>
                    {props.subtitle}
                </div>
                <div className={styles.divNext}>
                    <img src={imageBack}/>
                </div>
            </button>
            
        </div>
    );
}

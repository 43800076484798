import React from "react";
import { MainPage } from "./pages/MainPage";

function App() {
    return (
        <MainPage/>
    );
}

export default App;

import { atom } from "jotai";

export type ArticleAtom = {
    text:string,
    title:string,
    imageSrc:string,
}

const initial: ArticleAtom = {
    text: "",
    title: "",
    imageSrc: "",
};

export const globalArticleAtom = atom(initial);

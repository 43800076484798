
export function formatSecondsToMmSs(seconds: number | undefined | null) {
    if (seconds == null || isNaN(seconds))
        return "";

    let secondsPart = Math.round(seconds % 60);
    let minutesPart = Math.floor(seconds / 60);

    if (secondsPart === 60) {
        secondsPart = 0;
        minutesPart += 1;
    }

    return `${minutesPart.toString().padStart(2, "0")}:${secondsPart.toString().padStart(2, "0")}`;
}

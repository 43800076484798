import React, { useState } from "react";
import { useStrings } from "../localization/hooks/useStrings";
import styles from "./TextItem.module.scss";
import IconSrc from "../assets/images/icons/text-item-icon.svg";
import { Line } from "./Line";
import { TextModel } from "../models/TextModel";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { useSetAtom } from "jotai";
import { globalArticleAtom } from "../atoms/articleAtom";
import { page2DetailRoute } from "../pages/RoutePath";
import { AudioModel } from "../models/AudioModel";
import NameSquare from "./NameSquare";
import PauseSrc from "../assets/images/icons/icon-pause.svg";
import PlaySrc from "../assets/images/icons/icon-play-alt.svg";
import { formatSecondsToMmSs } from "../mz/timeUtils";
import { useAudioDurationSeconds } from "../mz/hooks/useAudioDurationSeconds";
import { useAtom } from "jotai";
import { globalAudioAtom } from "../atoms/audioAtom";

type Props = {
  model: AudioModel;
  className?: string;
  audioSrc: string;
};

export function AudioTextItem(props: Props) {
  const model = props.model;
  const setArticleDetails = useSetAtom(globalArticleAtom);
  const navigate = useAppNavigate();
  const strings = useStrings();
  const description = model.description ? strings[model.description] : "";

  const [audio, setAudio] = useState<HTMLAudioElement | null>();
  const audioSeconds = useAudioDurationSeconds(audio);

  const [globalAudio, setGlobalAudio] = useAtom(globalAudioAtom);

  const playing = globalAudio.key === model.audio && globalAudio.isPlaying;

  return (
    <div className={`${styles.wrapper} ${props.className ?? ""}`}>
      <img className={styles.icon} src={IconSrc} />
      <div className={`subtitle ${styles.title}`}>{strings[model.title]}</div>
      <NameSquare name={strings[model.name]} />

      <div className={styles.description}>{`${description?.substring(
        0,
        100
      )}...`}</div>

      <div className={styles.buttonWrapper}>
        <button
          className={`${styles.button} alt-all`}
          onClick={() => {
            if (description) {
              setArticleDetails({
                title: strings[model.title],
                text: description,
                imageSrc: "",
              });
              navigate(page2DetailRoute);
            }
          }}
        >
          <span className={`${styles.readMore}`}>
            {description ? strings.common$read_all : "Testo non presente"}
          </span>
        </button>

        <button
          className={`${styles.button} ${!playing ? "alt-all" : ""}`}
          onClick={() => {
            if (!audio) return;

            setGlobalAudio((current) => ({
              ...current,
              audioSrc: audio.src,
              key: model.audio,
              title: strings[model.title],
              subtitle: "Panel I",
              isPlaying: current.key !== model.audio || !current.isPlaying,
              isClosed: false,
            }));
          }}
        >
          
          <span
            className={`${styles.state} ${styles.playButton} ${
              playing ? styles.playing : styles.notPlaying
            } `}
          >
            {playing ? strings.common$playing : strings.common$play}
          </span>
          <img src={playing ? PauseSrc : PlaySrc} />
          
        </button>
      </div>

      <Line className={styles.line} />

      <audio
        ref={(el) => setAudio(el)}
        src={
          props.audioSrc +
          strings[model.name]?.toLowerCase() +
          strings[model.audio]
        }
        // src={dbgAudioSrc}
        preload="metadata"
      />
    </div>
  );
}

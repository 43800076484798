import React from 'react'
import styles from "./Page1.module.scss";
import { AudioFooter } from '../components/AudioFooter'
import { Header } from '../components/Header'
import { Line } from '../components/Line'
import { SocialFooter } from '../components/SocialFooter'
import { useStrings } from '../localization/hooks/useStrings'
import CoverSrc from "../assets/images/layout/pagani_benvenuto.png";
import { page1Entries } from '../models/AudioModel';
import { AudioItem } from '../components/AudioItem';


type Props = {
    className?: string
}


export default function Page1(props: Props) {
    const strings = useStrings();

    return (
        <>
            <Header hasMenuButton={true} />
            <div className={`page ${styles.page} ${props.className ?? ""}`}>
                <div className="title">
                    {strings.page1$title}
                </div>
                {/* <p className="subtitle page-subtitle">
                    {strings.page1$subtitle}
                </p> */}
                <img
                    className={styles.image}
                    src={CoverSrc}
                />
                
                    {/* <div className={`text ${styles.text}`}>
                        {strings.page1$text}
                    </div> */}
               
                {page1Entries.map(model => (
                    <AudioItem
                        key={model.title}
                        model={model}
                        audioSrc={'audio/welcome/'}
                    />
                ))}
                <SocialFooter className={styles.social} />
            </div>
            <AudioFooter />
        </>
    )
}

import React from "react";

import { useAudioCurrentSeconds } from "../mz/hooks/useAudioCurrentSeconds";
import { formatSecondsToMmSs } from "../mz/timeUtils";

type Props = {
    audio: HTMLAudioElement | null,
}

export function CurrentAudioSeconds(props: Props) {
    const audio = props.audio;
    const currentSeconds = useAudioCurrentSeconds(audio);

    return (
        <>
            {formatSecondsToMmSs(currentSeconds)}
        </>
    );
}

import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { HAS_MEDIA_SESSION, useMediaSession } from "@mebtte/react-media-session";
import { MediaSessionProps } from "@mebtte/react-media-session/dist/constant";

import { globalAudioAtom } from "../atoms/audioAtom";
import { useInit } from "../mz/hooks/useInit";

const mediaArtwork: MediaSessionProps["artwork"] = [{
    src: "logo512.png",
    sizes: "512x512",
    type: "image/png",
}];

export function GlobalAudio() {
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [globalAudio, setGlobalAudio] = useAtom(globalAudioAtom);

    useEffect(
        () => {
            setGlobalAudio(current => ({
                ...current,
                element: audio,
            }));
        },
        [audio],
    );

    useEffect(
        () => {
            if (!globalAudio.element || !globalAudio.audioSrc)
                return;

            if (globalAudio.isPlaying)
                globalAudio.element!.play();
            else
                globalAudio.element!.pause();
        },
        [globalAudio.isPlaying, globalAudio.element, globalAudio.audioSrc],
    );

    // rewind when key changes
    useEffect(
        () => {
            if (globalAudio.element)
                globalAudio.element.currentTime = 0;
        },
        [globalAudio.element, globalAudio.key],
    );

    // change state when audio stops or is paused externally (i.e. from notification)
    useEffect(
        () => {
            if (!audio)
                return;

            audio.onpause = () => setGlobalAudio(current => ({
                ...current,
                isPlaying: false,
            }));
        },
        [audio],
    );

    // media playing notification icon
    if (HAS_MEDIA_SESSION) {
        useMediaSession({
            artist: "Pagani",
            title: globalAudio.title,
            artwork: mediaArtwork,
        });
    }

    return (
        <audio
            // N.B. non posso fare direttamente così perché spacca tutto con l'errore:
            // Uncaught Error: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops
            // ref={e => setGlobalAudio(current => ({
            //     ...current,
            //     element: e,
            // }))}
            ref={e => setAudio(e)}
            src={globalAudio.audioSrc}
        />
    );
}

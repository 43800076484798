import React from "react";

import styles from "./SocialIcon.module.scss";

type Props = {
    href: string;
    src: string;
    className?: string;
}

export function SocialIcon(props: Props) {
    return (
        <a
            className={props.className}
            href={props.href}
            target="_blank"
            rel="noreferrer"
        >
            <img
                className={styles.icon}
                src={props.src}
            />
        </a>
    );
}

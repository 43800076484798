import React from "react";

import styles from "./Header.module.scss";
import LogoSrc from "../assets/images/layout/logo-pagani-w@2x.png";
import LogoAltSrc from "../assets/images/layout/logo-pagani-alt@2x.png";
import BackSrc from "../assets/images/icons/icon-back.svg";
import BackAltSrc from "../assets/images/icons/icon-back-alt.svg";
import MenuSrc from "../assets/images/icons/icon-panels.svg";
import { If } from "../mz/types/jsx-control-statements";
import { useNavigationType } from "react-router-dom";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { localeAtom } from "../localization/atoms/localeAtom";
import { useAtomValue } from "jotai";
import { languageRoute, panelsRoute } from "../pages/RoutePath";

type Props = {
    hasMenuButton?: boolean;
    alt?: boolean;
    className?: string;
}

export function Header(props: Props) {
    const navigate = useAppNavigate();
    const navigationType = useNavigationType();
    const locale = useAtomValue(localeAtom);

    // console.log("nav type", navigationType);

    const canGoBack = navigationType === "PUSH";

    return (
        <div className={`${styles.header} ${styles.alt} ${props.className ?? ""}`}>
            <div>
                <If condition={canGoBack}>
                    <img
                        className={styles.imgButton}
                        src={props.alt ? BackAltSrc : BackSrc}
                        alt="back"
                        onClick={() => {
                            if (canGoBack)
                                navigate(-1);
                        }}
                    />
                </If>
                <If condition={props.hasMenuButton! && !canGoBack}>
                    <img
                        className={styles.imgButton}
                        src={MenuSrc}
                        alt="menu"
                        onClick={() => navigate(panelsRoute)}
                    />
                </If>
            </div>
            <img
                className={`${styles.logo}`}
                src={ LogoAltSrc }
                alt="Logo Pagani"
            />
            <div>
                <If condition={!canGoBack}>
                    <button className={`${styles.buttonHeader}`} onClick={() => navigate(languageRoute)}>
                        {locale?.toUpperCase()}
                    </button>
                </If>
            </div>
        </div>
    );
}

import React from "react";

import styles from "./SocialFooter.module.scss";
import InstagramSrc from "../assets/images/icons/icon-insta.svg";
import FacebookSrc from "../assets/images/icons/icon-facebook.svg";
import YoutubeSrc from "../assets/images/icons/icon-yt.svg";
import { SocialIcon } from "./SocialIcon";

type Props = {
    className?: string;
}

export function SocialFooter(props: Props) {
    return (
        <div className={`${styles.wrapper} ${props.className ?? ""}`}>
            <SocialIcon
                className={styles.icon}
                href="https://www.instagram.com/paganiautomobili"
                src={InstagramSrc}
            />
            <SocialIcon
                className={styles.icon}
                href="https://www.facebook.com/PaganiAutomobili/"
                src={FacebookSrc}
            />
            <SocialIcon
                className={styles.icon}
                href="https://www.youtube.com/channel/UCO75zkg9l4U9cPdHVbj5DDw"
                src={YoutubeSrc}
            />
        </div>
    );
}

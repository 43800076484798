import React from "react";

import { PanelItem } from "../components/PanelItem";
import { useStrings } from "../localization/hooks/useStrings";
import { format } from "react-string-format";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { Header } from "../components/Header";
import { AudioFooter } from "../components/AudioFooter";
import { SocialFooter } from "../components/SocialFooter";
import { page1Route, page2Route} from "./RoutePath";
import { page1Entries, page2Entries } from "../models/AudioModel";
import { page3Entries } from "../models/TextModel";

type Props = {
    className?: string;
}

export function PanelsPage(props: Props) {
    const strings = useStrings();
    const navigate = useAppNavigate();

    return (
        <>
            <Header hasMenuButton={false} />
            <div className={`page whiteBg ${props.className ?? ""}`}>
                <PanelItem
                    title={strings.page1$title}
                    subtitle={format(strings.page1$panel, strings[page1Entries[0].name], strings[page1Entries[page1Entries.length - 1].name])}
                    bottom={format(strings.common$panel, "III")}
                    onClick={() => navigate(page1Route, { replace: true })}
                />
                <PanelItem
                    title={strings.page2$title}
                    subtitle={""}
                    bottom={format(strings.common$panel, "III")}
                    onClick={() => navigate(page2Route, { replace: true })}
                />
{/*                 <PanelItem
                    title={strings.page3$title}
                    subtitle={format(strings.page3$panel, page3Entries.length)}
                    bottom={format(strings.common$panel, "III")}
                    onClick={() => navigate(page3Route, { replace: true })}
                /> */}
            </div>
            <AudioFooter />
        </>
    );
}

import React, { useEffect } from "react";
import { useAtomValue } from "jotai";

import { localeAtom } from "../localization/atoms/localeAtom";
import { LanguagePage } from "./LanguagePage";
import { useAppNavigate } from "../hooks/useAppNavigate";
import { page1Route } from "./RoutePath";

type Props = {
    className?: string
}

export function SplashPage(props: Props) {
    const locale = useAtomValue(localeAtom);
    const navigate = useAppNavigate();

    useEffect(
        () => {
            if (locale)
                navigate(page1Route, { replace: true });
        },
        [locale],
    );

    return (
        <LanguagePage />
    );
}

import React from "react";
import { useAtom } from "jotai";

import styles from "./LanguagePage.module.scss";
import { localeAtom } from "../localization/atoms/localeAtom";
import { useStrings } from "../localization/hooks/useStrings";
import { Header } from "../components/Header";

type Props = {
    className?: string
}

export function LanguagePage(props: Props) {
    const [locale, setLocale] = useAtom(localeAtom);
    const strings = useStrings();
    console.log("LanguagePage", locale);

    return (
        <>
            <Header alt={true} />
            <div className={`page alt-color ${styles.page} ${props.className ?? ""}`}>
                <div className="title">
                    {strings.common$select_language}
                </div>
                <button
                    className={`${styles.button} alt-color ${locale !== "it" ? "unselected" : ""}`}
                    onClick={() => setLocale("it")}
                >
                    ITALIANO
                </button>
                <button
                    className={`${styles.button} alt-color ${locale !== "en" ? "unselected" : ""}`}
                    onClick={() => setLocale("en")}
                >
                    ENGLISH
                </button>
                <button
                    className={`${styles.button} alt-color ${locale !== "fr" ? "unselected" : ""}`}
                    onClick={() => setLocale("fr")}
                >
                    FRANÇAIS
                </button>
                <button
                    className={`${styles.button} alt-color ${locale !== "es" ? "unselected" : ""}`}
                    onClick={() => setLocale("es")}
                >
                    ESPAÑOL
                </button>
                <button
                    className={`${styles.button} alt-color ${locale !== "de" ? "unselected" : ""}`}
                    onClick={() => setLocale("de")}
                >
                    DEUTSCH
                </button>
            </div>
        </>
    );
}
